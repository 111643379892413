<template>

            <form @submit.prevent="save">
                <div class="form-group">
                    <label for="name">Nombre</label>
                    <input type="text" v-model="model.name" required id="name" class="form-control form-control-lg" placeholder="Nombre de la amenidad">
                </div>

                <!--
                <div class="form-group">
                    <label for="name">Icono</label>

                    <img v-if="pic" class="thumb" v-bind:src="pic"/>
                    <input type="file" class="form-control form-control-lg" v-on:change="selectFile" id="password"  placeholder="Icono" accept=".jpg,.jpeg,.png,.x-png,.gif">
                </div>
                -->

                <div class="form-group">
                    <submitbutton :label="isNewRecord?'Crear':'Actualizar'" :loading_flag="loading"/>
                </div>
            </form>

</template>
<script>
import submitbutton from '@/components/ui-feature/submit-button';
import api from '@/util/api.js';

export default {
    routeName:'amenities',
    name: 'formAmenity',
    components:{
        submitbutton
    },
    props:{

        id:{
            type:Number,
            required:false,
        }

    },  
    data(){

        return {
            
            model:{
                id:'',
                name:'',
                icon:''
            },
            
            pic:'',
            loading:false,

        }

    },

    methods:{


        selectFile(event) {
         this.model.icon = event.target.files[0];
        },

        save(){

            
            const data = this.createFormData(this.model);
            this.loading=true;

            api.request({
                url:this.me(this.isNewRecord?'/':this.model.id),
                data,
                headers:{'Content-type':'form/data'},
                method:this.isNewRecord?'POST':'PATCH',
                }).then(response=>{

                    this.$notify({message:"Elemento " +(this.isNewRecord?"creado":"actualizado"),type:"success"});
                    this.$emit('afterSave',response);
                    
                    
                }).catch(error=>{
                    
                    this.$notify({message:"Ocurrió un error",type:"error"});
                    console.log(error);

                }).finally(()=>{

                        this.loading=false;
                });
            
        }




    },


    
    
    mounted(){

    
        if(!this.isNewRecord){
                api.get(this.me(this.$props.id))
                .then(response=>{
                    
                        const data=response.data;

                        for (const key in data) {
                            if (this.model.hasOwnProperty(key)) {
                                this.model[key] = data[key];
                            }
                        }


                        
                        this.pic=this.$apiUrl+'/../..'+data.icon.replace('.','-sm.');


                }).catch(error=>{
                    console.log(error);
                });

        }



    }


}

</script>